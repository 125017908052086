import type { onErrorCaptured } from 'vue';
import { InternalError } from './InternalError';

const getComponentNamesStack = (instance: Parameters<Parameters<typeof onErrorCaptured>['0']>['1']) => {
  if (instance === null) {
    return null;
  }

  const stack: (string | null)[] = [];
  type IComponent = { type: { name?: string | null }; parent: IComponent | null };
  let currentComponent: IComponent = instance.$ as IComponent;
  do {
    stack.push(currentComponent.type.name ?? 'null');
    if (currentComponent.parent) {
      currentComponent = currentComponent.parent;
    }
  } while (currentComponent.parent !== null);

  return stack;
};
export class AppCaughtInternalError extends InternalError {
  name = 'Ошибка внутри "onErrorCaptured" в App.vue';

  private _error: Error;
  private _info: string;
  private _componentsStack: (string | null)[] | null;

  constructor(componentInstance: Parameters<Parameters<typeof onErrorCaptured>['0']>['1'], info: string, error: Error) {
    super(error.stack ?? null);

    this._componentsStack = getComponentNamesStack(componentInstance);
    this._info = info;
    this._error = error;
  }

  serialize() {
    let message = `${this._error.message};`;

    if (import.meta.env.MODE === 'development') {
      message += `\nerror.stack:\n${this._error.stack}`;
    }

    const params = this._getSentryParams();

    params.extra = {
      info: this._info,
      capturedErrorConstructorName: this._error.constructor.name,
    };

    InternalError.setPropIfValue(params, 'cause', this._error.cause);
    InternalError.setPropIfValue(
      params,
      'ncomponentsStack',
      this._componentsStack ? this._componentsStack?.join(', ') : null,
    );

    return {
      title: `[${this.constructor.name}]: ${this.name}`,
      message,
      params,
    };
  }
}
