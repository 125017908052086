import { LoggerMessage } from './loggerMessage';
import type { LoggerErrorContainer } from './loggerMessageContainer';

import { captureException, captureMessage } from '@sentry/vue';

type UConsoleMessageTypes = 'error' | 'warn' | 'message';
const CONSOLE_MESSAGE_STYLES: Record<UConsoleMessageTypes, string> = {
  error: 'font-weight: bold; color: red; font-size: 10px;',
  warn: 'font-weight: bold; color: orange; font-size: 10px;',
  message: 'font-weight: bold; color: blue; font-size: 10px;',
} as const;

const consoleTypeForLevel: Record<InstanceType<typeof LoggerMessage>['level'] | 'error', UConsoleMessageTypes> = {
  info: 'message',
  log: 'message',
  warning: 'warn',
  error: 'error',
};

let logger: null | Logger = null;
export class Logger {
  private constructor() {}

  static readonly LoggerMessage = LoggerMessage;

  static get instance() {
    if (logger === null) {
      logger = new Logger();
    }

    return logger;
  }

  private _logInConsole(level: InstanceType<typeof LoggerMessage>['level'] | 'error', message: LoggerMessage) {
    const type = consoleTypeForLevel[level];

    const msgIsEmpty = Array.isArray(message.msg) && message.msg.length === 0;
    const noMsgAtAll = !message.msg;

    const logMethod = msgIsEmpty || noMsgAtAll ? console.log : console.group;
    logMethod(`%c${message.title}`, CONSOLE_MESSAGE_STYLES[type]);

    if (Array.isArray(message.msg)) {
      for (const msg of message.msg) {
        console.log(...(Array.isArray(msg) ? msg : [msg]));
      }
    } else if (message.msg) {
      console.log(message.msg);
    }

    console.groupEnd();
  }

  error(error: LoggerErrorContainer, devOnly: boolean = false) {
    const message = error.serialize();

    if (import.meta.env.MODE === 'development') {
      this._logInConsole('error', new LoggerMessage('info', message.title, [message.message, message.params]));
      return;
    } else if (devOnly) {
      return;
    }

    const err = new Error(
      Array.isArray(message.message) ? message.message.join(`\n\n`) : (message.message ?? message.title),
    );
    err.name = message.title;

    captureException(err, message.params);
  }

  message(message: LoggerMessage, devOnly: boolean = false) {
    const serializedMessage = message.serialize();

    if (import.meta.env.MODE === 'development') {
      this._logInConsole(message.level, message);
    } else if (devOnly) {
      return;
    }

    captureMessage(serializedMessage.title, serializedMessage.params);
  }
}
