import { EStatusCodes, OPERATION_STATUS } from '~shared/orc';
import { Logger } from '@shared/libs/logger';
import { RestNetworkError } from '@shared/errors/RestNetworkError';

import { BASE_URL } from '../consts';

export const refreshToken = async () => {
  const response = await fetch(BASE_URL + '/api/auth/refresh', {
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
  });

  if (response.status !== EStatusCodes.UNAUTHORIZED && response.status !== EStatusCodes.NO_CONTENT) {
    requestIdleCallback(async () => {
      Logger.instance.error(
        new RestNetworkError({
          endpoint: '/api/auth/refresh',
          method: 'POST',
          responseBody: await response.text(),
          responseStatus: response.status,
        }),
      );
    });
  }

  return response.status === EStatusCodes.NO_CONTENT ? OPERATION_STATUS.SUCCESS : OPERATION_STATUS.ERROR;
};
