import { getIrtClientsFactory, IrtNetworkingLayout } from './core';

import { refreshToken } from '../utils/refreshToken';

/**
 * IRT - in real time
 */

const irtNetworkLayout = new IrtNetworkingLayout();
const clientsFactory = getIrtClientsFactory(irtNetworkLayout);

const systemEvents = ['updateToken'];
type ISystemEventsMap = {
  updateToken: [];
};

const irtSystemClient = clientsFactory<typeof systemEvents, ISystemEventsMap>('system', systemEvents);

irtSystemClient.on('updateToken', async () => {
  await refreshToken();
});

const mainEvents = ['CreateReceive', 'DeleteReceive', 'UpdateReceive'] as const;
type IMainEventsMap = {
  CreateReceive: [task: Record<string, any>];
  DeleteReceive: [taskId: number];
  UpdateReceive: [task: Record<string, any>];
};

const irtClient = clientsFactory<typeof mainEvents, IMainEventsMap>('main', mainEvents);

export { irtNetworkLayout, irtClient, irtSystemClient };
