import { InternalError } from './InternalError';

type IIrtNetworkErrorArgs = {
  event: string;
  handleId?: string;
  payload?: string;
};

export class IrtNetworkError extends InternalError {
  name = 'IRT Сетевая ошибка';

  private _event: string;
  private _handleId?: string;
  private _payload: string | null;

  constructor(args: IIrtNetworkErrorArgs, ...errorArgs: Parameters<typeof Error>) {
    super(args.handleId ?? null, ...errorArgs);

    this._event = args.event;
    this._payload = args.payload ?? null;
    this._handleId = args.handleId;
  }

  serialize() {
    const params = this._getSentryParams();
    IrtNetworkError.setPropIfValue(params.extra, 'payload', this._payload?.slice(0, IrtNetworkError.SAFE_QNT_OF_CHARS));
    IrtNetworkError.setPropIfValue(params.extra, 'errorMessage', this.message);

    const message = `${this._event}: ${this._handleId}`;

    return {
      title: this.title,
      message,
      params,
    };
  }
}
