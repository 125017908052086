import type { OPERATION_STATUS } from '@shared/libs/orc';

import { InternalError } from '@shared/errors/InternalError';

export type UTokenUpdatingStatus = (typeof OPERATION_STATUS)['SUCCESS'] | (typeof OPERATION_STATUS)['ERROR'];

let locking = null as Promise<UTokenUpdatingStatus> | null;

/**
 * Модуль является по сути реализацией Synchronization Event. В роли потоков выступает каждый отдельный запрос[request].
 * https://en.wikipedia.org/wiki/Event_(synchronization_primitive)
 *
 * Сам факт существования промиса(locking !== null) означает, что ивент для приостановки выполнения был создан.
 * Дальше запросы ожидают, пока промис не будет разрешен[resolve]. Как только промис таковым становится,
 * значит, что запросы могут продолжить выполнение.
 *
 * Первый запрос, который понимает, что токен устарел, блокирует networking до тех пор, пока токен не будет обновлен.
 * Все последующие запросы должны ожидать, пока networking не будет разблокирован.
 */

export const isLocked = () => !!locking;

export const lock = () => {
  if (locking) {
    throw new InternalError(null, 'Невозможно заблокировать networking', { cause: 'networking уже заблокирован' });
  }

  let resolve = null as null | ((value: UTokenUpdatingStatus | PromiseLike<UTokenUpdatingStatus>) => void);
  locking = new Promise<UTokenUpdatingStatus>((promiseResolve) => {
    resolve = (...args) => {
      locking = null;
      promiseResolve(...args);
    };
  });

  return resolve!;
};

export const waitForUnlock = () => {
  if (locking !== null) {
    return locking;
  }

  throw new Error('Нельзя ждать разблокировки нетворкинга без проверки с помощью isLocked');
};

export const networking = {
  isLocked,
  lock,
  waitForUnlock,
};
