import { ref, readonly } from 'vue';
import { SessionModel } from '@/entities/session';
import { irtNetworkLayout } from '@shared/api/irt';

export const useLogout = () => {
  const sessionStore = SessionModel.useSessionStore();

  const logoutInProgress = ref(false);

  const clearSessionUserTasks = async () => {};
  const clearAccountInfo = async () =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(null);
      }, 1500);
    });

  const onUserLogouted = async () => {
    if (!sessionStore.sessionDataLoaded) {
      return;
    }

    logoutInProgress.value = true;

    await Promise.allSettled([clearSessionUserTasks(), clearAccountInfo(), irtNetworkLayout.disconnect()]);

    sessionStorage.clear();
    localStorage.clear();
    sessionStore.clear();

    logoutInProgress.value = false;
    sessionStore.sessionDataLoaded = false;
  };

  return {
    onUserLogouted,
    logoutInProgress: readonly(logoutInProgress),
  };
};
