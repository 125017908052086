import type { Middleware } from 'openapi-fetch';

import { RestNetworkError } from '@shared/errors/RestNetworkError';
import { EStatusCodes } from '@shared/libs/orc';
import { Logger } from '@shared/libs/logger';

const exceptions = [EStatusCodes.UNAUTHORIZED, EStatusCodes.FORBIDDEN, EStatusCodes.IM_A_TEAPOT];
const statusRegExps = /[45]\d\d/;

export const sentryMiddleware: Middleware = {
  async onResponse({ request, response }) {
    const errorStatus = statusRegExps.test(response.status.toString());

    if (!exceptions.includes(response.status) && errorStatus) {
      const clonedResponse = response.headers.get('Content-Type')?.includes('application/json')
        ? response.clone()
        : null;
      requestIdleCallback(async () => {
        const bodyExists = !!clonedResponse?.body;
        let parsedBody = bodyExists ? await clonedResponse.json() : 'Не сохранил, т.к. не JSON';

        if (typeof parsedBody === 'object' && parsedBody !== null) {
          delete parsedBody['spanId'];
          delete parsedBody['traceId'];
          parsedBody = JSON.stringify(parsedBody, null, 1);
        }

        Logger.instance.error(
          new RestNetworkError({
            endpoint: response.url,
            method: request.method,
            responseStatus: response.status,
            responseBody: `${parsedBody}`,
          }),
        );
      });
    }

    return response;
  },
};
