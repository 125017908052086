import { defineStore } from 'pinia';
import { ref, readonly } from 'vue';

import { OPERATION_STATUS } from '~shared/orc';

import { type ISignInRequest, api } from '../api';
import { Logger } from '@shared/libs/logger';
import { InternalError } from '@shared/errors/InternalError';

export const useSessionStore = defineStore('session', () => {
  const username = ref<string | null>(null);
  const signedIn = ref<null | boolean>(null);

  const sessionDataLoaded = ref(false);

  const clear = () => {
    username.value = null;
    signedIn.value = false;
    sessionDataLoaded.value = false;
  };

  const checkAuthOnInit = async () => {
    try {
      const isAuthedResult = await api.isAuthed();
      signedIn.value = isAuthedResult;
    } catch (err) {
      Logger.instance.error(
        new InternalError(null, 'Не удалось проверить авторизацию на инициализации', { cause: err }),
      );
    }
  };

  const signIn = async (body: ISignInRequest) => {
    const result = await api.signIn(body);

    if (result.status === OPERATION_STATUS.SUCCESS) {
      signedIn.value = true;
      username.value = body.username;
    }

    return result;
  };

  const logout = async () => {
    return await api.logout();
  };

  return {
    username: readonly(username),
    signedIn: readonly(signedIn),
    sessionDataLoaded,

    clear,

    signIn,
    logout,
    checkAuthOnInit,
  };
});
