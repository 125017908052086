import { createOrc, OPERATION_STATUS } from '~shared/orc';

/** @description Функция используется, чтобы оборачивать функции, выполняющие запрос по АПИ;
 * В случае, если ответ от сервера НЕ БЫЛ ОБРАБОТАН или при запросе произошла ошибка, то
 * функция вернет ORC ошибки;
 *
 * Функция используется для автоматического примешавания ORC с ошибкой в результат выполнения коллбека;
 */

export type IFetchFnParams<Q extends object = Record<string, never>> = { signal?: AbortSignal | null; query: Q };

// Exclude<Awaited<R>, undefined> : never
export const wrapHttpRequest = async <
  T extends (...args: any) => any,
  R extends T extends (...args: any[]) => infer RawR ? Exclude<Awaited<RawR>, undefined> : never,
>(
  cb: T,
) => {
  try {
    const result = (await cb()) as R;

    if (result === undefined) {
      return createOrc({
        status: OPERATION_STATUS.ERROR,
      });
    }

    return result;
  } catch (err) {
    console.error(err);

    if ((err as Error).name) {
      return createOrc({
        status: OPERATION_STATUS.ABORTED,
      });
    }

    return createOrc({
      status: OPERATION_STATUS.ERROR,
    });
  }
};
