import createClient from 'openapi-fetch';
import type { ClientOptions } from 'openapi-fetch';

import { type paths as authPaths, type components as authComponents } from './openapi/authService';

import { middlewares } from '../middlewares';
import { BASE_URL } from '../consts';

const clientsConfig: ClientOptions = {
  baseUrl: BASE_URL,
  headers: {},
  credentials: 'include',
};

// error в возврате функции это модели респонсов 4xx и 5хх статусов
const fetcher = {
  auth: createClient<authPaths>(clientsConfig),
};

let prop: keyof typeof fetcher;
for (prop in fetcher) {
  middlewares.forEach((middleware) => fetcher[prop].use(middleware));
}

type IComponentsProto = { schemas: object };
type UAvailableComponents<T extends IComponentsProto> = keyof T['schemas'];

export type IOEntity<
  components extends IComponentsProto,
  component extends UAvailableComponents<components>,
> = components['schemas'][component];
// type IError = {
//   title: string;
//   status: number;

//   detail: IError | string;

//   instance?: string;
//   errors?: Record<string, unknown>;
// };

type IOAuthEntity<component extends UAvailableComponents<authComponents>> = IOEntity<authComponents, component>;
export { fetcher, type IOAuthEntity };

/* DEMO
;( async () => {
  const res: IORes<'Template'> = await fetcher.path( '/Template' ).method( 'post' ).create()( {
    name: 'sd',
    templateTypeId: 1,
    body: 'sdsds',
  } )

  const template: IOEntity<'Template'> = res.data

} )()
*/
