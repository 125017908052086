<script lang="ts">
import { defineAsyncComponent, onErrorCaptured } from 'vue';
import { PLogoutBtn } from '@/features/auth';

const ZNotifications = defineAsyncComponent(() => import('@shared/ui/singletones/ZNotifications'));
const ZSubmitDialog = defineAsyncComponent(() => import('@shared/ui/singletones/ZSubmitDialog'));
</script>

<script setup lang="ts">
import { Logger } from '@shared/libs/logger';
import { InternalError } from '@shared/libs/errors/InternalError';
import { AppCaughtInternalError } from '@shared/libs/errors/AppCaughtInternalError';

import { useProvidersAtProject } from '~shared/providers';
import { pages } from './router/routes';

defineOptions({
  name: 'TheApp',
});

onErrorCaptured((err, instance, info) => {
  try {
    const vueWrapperError = new AppCaughtInternalError(instance, info, err);
    Logger.instance.error(vueWrapperError);
    return false;
  } catch (inHookError) {
    const wrapperForOriginalError = new InternalError(err.stack ?? null);
    if (err instanceof Error) {
      wrapperForOriginalError.message = err.message;
      wrapperForOriginalError.cause = err.cause;
      wrapperForOriginalError.name = err.name;
      wrapperForOriginalError.stack = err.stack;
    } else {
      wrapperForOriginalError.message = err;
    }
    Logger.instance.error(wrapperForOriginalError);

    const wrapperForInHookError = new InternalError(
      `Не удалось обработать ошибку с помощью AppCaughtInternalError\nerror message: ${(inHookError as Error).message ?? err}`,
    );
    if (inHookError instanceof Error) {
      wrapperForInHookError.cause = inHookError.cause;
      wrapperForInHookError.name = inHookError.name;
      wrapperForInHookError.stack = inHookError.stack;
    }
    Logger.instance.error(wrapperForInHookError);
  }
});

useProvidersAtProject(pages);
</script>

<template>
  <div>
    <Teleport to="body">
      <ZNotifications />
      <ZSubmitDialog />
    </Teleport>

    <VApp class="h-screen">
      <VNavigationDrawer>
        <VList>
          <VListItem title="Navigation drawer"></VListItem>
        </VList>
      </VNavigationDrawer>

      <VAppBar title="Application bar1">
        <PLogoutBtn />
      </VAppBar>

      <VMain>
        <RouterView v-slot="{ Component }">
          <VFadeTransition mode="out-in">
            <component :is="Component" />
          </VFadeTransition>
        </RouterView>
      </VMain>
    </VApp>
  </div>
</template>
