import type { Scope } from '@sentry/vue';
import type { ILoggerSerializedMessage } from './types';

type IExtras = Parameters<Scope['setExtras']>['0'];

/**
 * Individual extra data items are limited to 16kB. Total extra data is limited to 256kb.
 * https://develop.sentry.dev/sdk/expected-features/data-handling/
 */

const MAX_BYTES_PER_PROP = 16384;
const MAX_BYTES_PER_CHAR_IN_UTF8 = 4;

const SAFE_QNT_OF_CHARS = MAX_BYTES_PER_PROP / MAX_BYTES_PER_CHAR_IN_UTF8;

const generateHash = (value: string) => {
  let hash = 0,
    i,
    chr;
  if (value.length === 0) return hash.toString();
  for (i = 0; i < value.length; i++) {
    chr = value.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash.toString();
};

const setPropIfValue = (obj: Record<string, any>, prop: string, value: any) => {
  if (value) {
    obj[prop] = value;
  }
};

interface ILoggerPayloadContainer {
  readonly _hash: string | null;
  serialize(): ILoggerSerializedMessage<'exception' | 'message' | null>;
}

export abstract class LoggerMessageContainer implements ILoggerPayloadContainer {
  static readonly setPropIfValue = setPropIfValue;
  static readonly SAFE_QNT_OF_CHARS = SAFE_QNT_OF_CHARS;

  readonly _hash: string | null;

  constructor(hashSeed?: string) {
    this._hash = hashSeed ? generateHash(hashSeed) : null;
  }

  protected _getSentryParams() {
    const params: ILoggerSerializedMessage<'exception'>['params'] = {};
    LoggerMessageContainer.setPropIfValue(params, 'fingerprint', this._hash);

    return params;
  }

  abstract serialize(): ILoggerSerializedMessage<'message'>;
}

export abstract class LoggerErrorContainer extends Error implements ILoggerPayloadContainer {
  static readonly setPropIfValue = setPropIfValue;
  static readonly SAFE_QNT_OF_CHARS = SAFE_QNT_OF_CHARS;

  get title() {
    return `[${this.constructor.name}]: ${this.name}`;
  }

  readonly _hash: string | null;

  constructor(hashSeed: string | null, ...errorArgs: Parameters<typeof Error>) {
    super(...errorArgs);

    const valueUsedForHash = hashSeed ?? this.message;
    this._hash = valueUsedForHash ? generateHash(valueUsedForHash) : null;
  }

  protected _getSentryParams() {
    const params: ILoggerSerializedMessage<'exception'>['params'] & {
      extra: IExtras;
    } = {
      extra: {},
    };
    params.level = 'error';
    LoggerMessageContainer.setPropIfValue(params, 'fingerprint', this._hash);
    LoggerMessageContainer.setPropIfValue(params.extra, 'cause', this.cause);

    return params;
  }

  abstract serialize(): ILoggerSerializedMessage<'exception'>;
}
