import { ref, readonly } from 'vue';
import { irtNetworkLayout } from '@shared/api/irt';
import type { INotificationStore } from '@shared/ui/singletones/ZNotifications';

import { SessionModel } from '@/entities/session';

// TODO Попробовать добавить фичу, чтобы на инициализации в лоадере менялся статус. Сначала "Инициализация приложения", потом "Проверка доступа", потом "Загрузка пользовательских данных"
export const useAuth = (notificationStore: INotificationStore) => {
  const sessionStore = SessionModel.useSessionStore();

  const authInProgress = ref(false);

  const loadSessionUserTasks = async () => {};
  const loadAccountData = async () => {
    // Тут вызов чего-то вроде /me, для получения данных о текущем аккаунте - лог, пас, емаил и т.д.
  };

  const onUserAuthed = async () => {
    if (sessionStore.sessionDataLoaded) {
      return;
    }

    authInProgress.value = true;

    const [, , irtConnectionResult] = await Promise.allSettled([
      loadAccountData(),
      loadSessionUserTasks(),
      irtNetworkLayout.connect(),
    ]);

    if (irtConnectionResult.status === 'rejected') {
      notificationStore.showSnackbar.error({
        text: 'Не удалось произвести подключение к серверу для получения данных в реальном времени[IRT].',
      });
    }

    authInProgress.value = false;
    sessionStore.sessionDataLoaded = true;
  };

  return {
    onUserAuthed,
    authInProgress: readonly(authInProgress),
  };
};
