import { LoggerMessageContainer } from './loggerMessageContainer';

export type UMessageLevels = 'info' | 'log' | 'warning';
// TODO Надо доработать логирование. Нужно сделать, чтобы можно было ввыводить message в консоль как collapsedGroup. При этом можно внутри группы логировать err.trace
export class LoggerMessage extends LoggerMessageContainer {
  readonly msg?: string | any[];
  readonly title: string;
  readonly level: UMessageLevels;

  constructor(level: UMessageLevels, title: string, msg?: string | any[]) {
    super(title);

    this.msg = msg;
    this.title = title;
    this.level = level;
  }

  serialize() {
    const params = this._getSentryParams();

    params.level = this.level;

    if (Array.isArray(this.msg)) {
      params.extra = {
        msgs: JSON.stringify(this.msg, null, 1),
      };
    }

    return {
      title: this.title,
      message: Array.isArray(this.msg) ? '' : this.msg,
      params,
    };
  }
}
