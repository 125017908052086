import { LoggerErrorContainer } from '@shared/libs/logger';

export class InternalError extends LoggerErrorContainer {
  name = 'Внутреняя ошибка';

  serialize() {
    let message = `${this.message};`;

    if (import.meta.env.MODE === 'development') {
      message += `\nerror.stack:\n${this.stack}`;
    }

    const params = this._getSentryParams();

    return {
      title: this.title,
      message,
      params,
    };
  }
}
