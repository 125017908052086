import type { Middleware } from 'openapi-fetch';
import { EStatusCodes, OPERATION_STATUS } from '@shared/libs/orc';

import { networking, type UTokenUpdatingStatus } from '../utils/networking';

import { Logger } from '@shared/libs/logger';
import { InternalError } from '@shared/errors/InternalError';

import { refreshToken } from '../utils/refreshToken';

const refetch = async (request: Request) => {
  const requestInit: RequestInit = {
    cache: request.cache,
    credentials: request.credentials,
    integrity: request.integrity,
    method: request.method,
    mode: request.mode,
    redirect: request.redirect,
    referrer: request.referrer,
    referrerPolicy: request.referrerPolicy,
    headers: request.headers,
    body: request.body ? await request.arrayBuffer() : undefined,
  };

  return await fetch(request.url, requestInit);
};

const requestWrappersMap = new Map<string, { request: Request; waitingStatus: UTokenUpdatingStatus | null }>();
export const authMiddleware: Middleware = {
  onResponse: async ({ id, response }) => {
    const requestWrapper = requestWrappersMap.get(id);
    requestWrappersMap.delete(id);

    if (response.status !== EStatusCodes.UNAUTHORIZED) {
      return response;
    }

    if (!requestWrapper) {
      Logger.instance.error(
        new InternalError(null, 'Некорректное состояния в auth-middleware', {
          cause: `Отсутвует requestWrapper, который должен был назначиться в onRequest хуке.
Т.к. запрос повалился с 401 статусом, то это значит, что в реквест хуке токен не был обновлен
успешно, т.е. requestWrapper должен АБСОЛЮТНО точно быть в мапе requestWrappersMap`,
        }),
      );

      return response;
    }

    const ableToUpdateToken = response.headers.get('X-Token-Expired') === 'true';
    if (!ableToUpdateToken) {
      return response;
    }

    const { request, waitingStatus: waitingStatusOnRequest } = requestWrapper;

    const firstFailedReq = waitingStatusOnRequest === null && !networking.isLocked();
    const notFirstFailedReq = waitingStatusOnRequest === null && networking.isLocked();

    let tokenUpdatingStatus = (waitingStatusOnRequest ?? null) as UTokenUpdatingStatus | null;

    if (firstFailedReq) {
      const unlockNetworking = networking.lock();
      const tokenUpdatingStatus = await refreshToken();
      unlockNetworking(tokenUpdatingStatus);
    } else if (notFirstFailedReq) {
      tokenUpdatingStatus = await networking.waitForUnlock();
    }

    if (tokenUpdatingStatus === OPERATION_STATUS.ERROR) {
      return response;
    } else if (tokenUpdatingStatus === OPERATION_STATUS.SUCCESS) {
      return await refetch(request);
    }
  },
  onRequest: async ({ id, request }) => {
    let waitingStatus = null as UTokenUpdatingStatus | null;
    if (networking.isLocked()) {
      waitingStatus = await networking.waitForUnlock();
    }

    if (waitingStatus !== OPERATION_STATUS.SUCCESS) {
      requestWrappersMap.set(id, { request: request.clone(), waitingStatus });
    }
  },
};
