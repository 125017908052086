import { type NavigationGuard } from 'vue-router';

import { useAuth, useLogout } from '@/features/auth';
import { SessionModel } from '@/entities/session';
import { useNotificationStore } from '@shared/ui/singletones/ZNotifications';
import { hidePreloader } from '@shared/libs/preloader';

export const onInitGuard: NavigationGuard = async (to, from, next) => {
  const sessionStore = SessionModel.useSessionStore();

  const authChecked = sessionStore.signedIn !== null;
  if (!authChecked) {
    await sessionStore.checkAuthOnInit();
  }

  if (sessionStore.signedIn) {
    const notificationStore = useNotificationStore();
    const { onUserAuthed } = useAuth(notificationStore);
    onUserAuthed();
  } else {
    const { onUserLogouted } = useLogout();
    onUserLogouted();
    // TODO Мультитаб Если в новой вкладке не удалось авторизироваться, то надо и в остальных вкладках логаут делать с очисткой сессии
  }

  hidePreloader();
  next();
};
