import { InternalError } from './InternalError';

type IRestNetworkErrorArgs = {
  endpoint: string;
  method: string;
  responseStatus: number;
  responseBody?: string;
};

/**
 * Individual extra data items are limited to 16kB. Total extra data is limited to 256kb.
 * https://develop.sentry.dev/sdk/expected-features/data-handling/
 */

export class RestNetworkError extends InternalError {
  name = 'Сетевая ошибка';

  private _endpoint: string;
  private _method: string;
  private _responseStatus: number;
  private _responseBody?: string;

  constructor(networkArgs: IRestNetworkErrorArgs, ...errorArgs: Parameters<typeof Error>) {
    const hash =
      networkArgs.endpoint +
      networkArgs.method +
      (networkArgs.responseBody?.slice(0, RestNetworkError.SAFE_QNT_OF_CHARS) ?? '');
    super(hash, ...errorArgs);

    this._endpoint = networkArgs.endpoint;
    this._method = networkArgs.method;
    this._responseStatus = networkArgs.responseStatus;
    this._responseBody = networkArgs.responseBody;
  }

  serialize() {
    const message = `${this._method} ${this._endpoint}`;

    const params = this._getSentryParams();
    RestNetworkError.setPropIfValue(
      params.extra,
      'responseBody',
      this._responseBody?.slice(0, RestNetworkError.SAFE_QNT_OF_CHARS),
    );
    RestNetworkError.setPropIfValue(params.extra, 'errorMessage', this.message);
    params.extra.responseStatus = this._responseStatus;

    return {
      title: this.title,
      message,
      params,
    };
  }
}
