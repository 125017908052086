import * as SignalR from '@microsoft/signalr';

import { text2Hash } from '@shared/libs/utils/text2Hash';

import { Logger, throwIfDevElseLogger } from '@shared/libs/logger';
import { InternalError } from '@shared/errors/InternalError';
import { IrtNetworkError } from '@shared/errors/IrtNetworkError';

import { networking } from '../utils/networking';
import { OPERATION_STATUS } from '~shared/orc';

const __IS_DEBBUGING = import.meta.env.DEV && true;
const getIrtMsg = (msg: string) => `IRT: ${msg}`;

export class IrtNetworkingLayout {
  private _connection: SignalR.HubConnection | null = null;
  private _events: Map<string, (...args: any[]) => void> = new Map();

  add2Listening(event: string, cb: (...args: any[]) => void) {
    if (this._events.has(event)) {
      throw new InternalError(null, getIrtMsg('ивент уже был добавлен для прослушивания'), { cause: { event } });
    }

    this._events.set(event, cb);
  }

  connect() {
    return new Promise<(typeof OPERATION_STATUS)[keyof typeof OPERATION_STATUS]>(async (resolve, reject) => {
      try {
        if (networking.isLocked()) {
          if (__IS_DEBBUGING) {
            Logger.instance.message(new Logger.LoggerMessage('log', getIrtMsg('networking залочен')));
          }

          const waitingStatus = await networking.waitForUnlock();

          const msg = getIrtMsg('networking разлочен с ошибочным статусом, не подключаюсь');
          if (__IS_DEBBUGING && waitingStatus === OPERATION_STATUS.ERROR) {
            Logger.instance.message(new Logger.LoggerMessage('log', msg));
          }

          reject(new InternalError(null, msg));
          return;
        }

        this._connection = new SignalR.HubConnectionBuilder()
          .withUrl(`${import.meta.env.VITE_BACKEND_URL}/api/notification`)
          .build();

        this._events.forEach((cb, event) => this._connection!.on(event, cb));

        // setInterval(() => {
        //   // TODO Это мок, выпилитть потом
        //   this._events.get('updateToken')?.([1, 2, 3]);
        // }, 1000);

        if (__IS_DEBBUGING) {
          Logger.instance.message(new Logger.LoggerMessage('log', getIrtMsg("успешно подключился в IRT Layout'е")));
        }

        resolve(OPERATION_STATUS.SUCCESS);
      } catch (err) {
        const internalError = new InternalError(null, getIrtMsg('получил ошибку в IrtNetworkingLayout.connect'), {
          cause: err,
        });
        Logger.instance.error(internalError);
        reject(internalError);
      }
    });
  }

  disconnect() {
    return new Promise<(typeof OPERATION_STATUS)[keyof typeof OPERATION_STATUS]>(async (resolve, reject) => {
      try {
        if (this._connection) {
          await this._connection.stop();
          this._connection = null;

          if (__IS_DEBBUGING) {
            Logger.instance.message(new Logger.LoggerMessage('log', getIrtMsg('произвел disconnect активной сессии')));
          }
        } else {
          Logger.instance.message(
            new Logger.LoggerMessage('log', getIrtMsg('попытка произвести disconnect при отсутствующей сессии')),
          );
        }

        resolve(OPERATION_STATUS.SUCCESS);
      } catch (err) {
        const internalError = new InternalError(null, getIrtMsg('получил ошибку в IrtNetworkingLayout.disconnect'), {
          cause: err,
        });
        Logger.instance.error(internalError);
        reject(internalError);
      }
    });
  }
}

type IEventsPayload = Record<string, any[]>;
type IIrlHandle<T extends any[]> = {
  id: string;
  fn: (...args: T) => void;
  order: number | null;
};

type IHandleParams = {
  once?: boolean;
  order?: number;
};

class IrtNetworkingClient<T extends IEventsPayload> {
  private _handles = {} as Record<keyof T, IIrlHandle<T[keyof T]>[]>;
  private _name: string;
  private _supportedEvents: readonly string[];

  private _addingHandleLocked: boolean = false;
  private _addingHandlesQueue: Array<(value: unknown) => void> = [];

  private _triggerGot: boolean = false;

  constructor(clientName: string, supportedEvents: readonly string[]) {
    this._name = clientName;
    this._supportedEvents = supportedEvents;
  }

  get trigger() {
    if (this._triggerGot) {
      throw new InternalError(null, getIrtMsg('тригер-функция данного клиента уже была забрана'), {
        cause: { clientName: this._name },
      });
    }

    this._triggerGot = true;
    return (event: string, ...args: any[]) => {
      if (this._handles[event]) {
        this._handles[event].forEach((handle) => handle.fn(...(args as any)));
      }
    };
  }

  private _getLogPrefix(event: PropertyKey, handleId: string) {
    return `[${this._name}:${event.toString()}:${handleId}]`;
  }

  private _addHandleInOrder(oldHandles: Array<IIrlHandle<T[keyof T]>>, newHandle: IIrlHandle<T[keyof T]>) {
    const handleWithGreaterOrderInx = oldHandles.findIndex((handle) => (newHandle.order ?? -1) > (handle.order ?? -1));
    const insertInx = handleWithGreaterOrderInx === -1 ? oldHandles.length : handleWithGreaterOrderInx;

    return [...oldHandles.slice(0, insertInx), newHandle, ...oldHandles.splice(insertInx + 1)] as Array<
      IIrlHandle<T[keyof T]>
    >;
  }

  private _checkIfHanbleWithIdExists(oldHandles: Array<IIrlHandle<T[keyof T]>>, id: string) {
    return oldHandles.findIndex((handle) => handle.id === id) !== -1;
  }

  private async _getHandleFnWrapper<EventName extends keyof T>(
    event: EventName,
    fn: (...args: T[EventName]) => void,
    params?: IHandleParams,
  ): Promise<IIrlHandle<T[EventName]>> {
    const handle = {
      id: await text2Hash(fn.toString()),
      fn: (...args: any) => {
        if (__IS_DEBBUGING) {
          Logger.instance.message(
            new Logger.LoggerMessage('log', `${this._getLogPrefix(event, handle.id)}: вызов слушателя`),
          );
        }

        if (params?.once) {
          Logger.instance.message(
            new Logger.LoggerMessage('log', `${this._getLogPrefix(event, handle.id)}: отключаю одноразовый ивент`),
          );

          this.off(event, handle.id);
        }

        try {
          fn(...args);
        } catch (err) {
          Logger.instance.error(
            new IrtNetworkError(
              {
                event: event.toString(),
                handleId: handle.id,
                payload: JSON.stringify(args),
              },
              '',
              { cause: err },
            ),
          );
        }
      },
      order: params?.order ?? null,
    };

    return handle;
  }

  async on<EventName extends keyof T>(event: EventName, fn: (...args: T[EventName]) => void, params?: IHandleParams) {
    try {
      if (this._addingHandleLocked) {
        await new Promise((resolve) => {
          this._addingHandlesQueue.push(resolve);
        });
      }

      this._addingHandleLocked = true;

      const oldHandles = this._handles[event] ?? [];
      const handle = await this._getHandleFnWrapper(event, fn, params);

      if (__IS_DEBBUGING) {
        Logger.instance.message(
          new Logger.LoggerMessage('log', `${this._getLogPrefix(event, handle.id)}: добавление слушателя`),
        );
      }

      const eventIsUnsupported = __IS_DEBBUGING && !this._supportedEvents.includes(event.toString());
      if (eventIsUnsupported) {
        throw new InternalError(
          handle.id,
          `${this._getLogPrefix(event, handle.id)}: не получилось добавить Handle в IRT клиент`,
          {
            cause: `Ивент не поддерживается данным клиентом`,
          },
        );
      }

      const handleAlreadyExists = this._checkIfHanbleWithIdExists(oldHandles, handle.id);
      if (handleAlreadyExists) {
        if (__IS_DEBBUGING) {
          throw new InternalError(
            handle.id,
            `${this._getLogPrefix(event, handle.id)}: не получилось добавить Handle в IRT клиент`,
            {
              cause: 'Handle уже был добавлен в список обработчиков ивента',
            },
          );
        }

        return handle.id;
      }

      this._handles[event] = this._addHandleInOrder(oldHandles, handle as IIrlHandle<T[keyof T]>);
      return handle.id;
    } finally {
      const unlockNextAdding = this._addingHandlesQueue.shift();

      if (unlockNextAdding) {
        unlockNextAdding(null);
      } else {
        this._addingHandleLocked = false;
      }
    }
  }

  off<EventName extends keyof T>(event: EventName, id: string) {
    if (__IS_DEBBUGING) {
      Logger.instance.message(new Logger.LoggerMessage('log', `${this._getLogPrefix(event, id)}: удаление слушателя`));
    }

    const handleInx = this._handles[event].findIndex((handle) => handle.id === id);
    if (handleInx === -1) {
      if (__IS_DEBBUGING) {
        throw new InternalError(id, `${this._getLogPrefix(event, id)}: не получилось удалить handle`, {
          cause: 'Не смог найти handle по id',
        });
      }
    }

    this._handles[event].splice(handleInx, 1);
  }
}

export const getIrtClientsFactory = (irtLayout: InstanceType<typeof IrtNetworkingLayout>) => {
  const clientsNamesMap = new Map<string, true>();

  return <Events extends readonly string[], IEventsPayload extends Record<string, any>>(
    name: string,
    eventsList: Events,
  ) => {
    const client = new IrtNetworkingClient<IEventsPayload>(name, eventsList);

    if (clientsNamesMap.has(name)) {
      throwIfDevElseLogger(
        new InternalError(null, 'IRT-клиент с неуникальным именем', {
          cause: { clientName: name },
        }),
      );
    }

    const clientTriggerFn = client.trigger;
    eventsList.forEach((event) => {
      if (__IS_DEBBUGING) {
        Logger.instance.message(
          new Logger.LoggerMessage('log', getIrtMsg(`Ивент "${event}" обрабатывается клиентом "${name}"`)),
        );
      }

      irtLayout.add2Listening(event, (...args: any[]) => clientTriggerFn(event, ...args));
    });

    clientsNamesMap.set(name, true);

    return client;
  };
};
