import { type LoggerErrorContainer } from './loggerMessageContainer';
import { Logger } from './logger';

export const throwIfDevElseLogger = (err: LoggerErrorContainer) => {
  if (import.meta.env.DEV) {
    throw err;
  } else {
    Logger.instance.error(err);
  }
};
