import { vuetify } from './plugins/vuetify';
import App from './App.vue';
import * as Sentry from '@sentry/vue';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { router } from './router';

import '@shared/styles/global.scss';

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(router);

app.use(vuetify);

Sentry.init({
  app,
  dsn: 'https://2e280a8babb0f078e6f19e66e8f91119@sentry-crm.2pp.dev/127',
  environment: import.meta.env.MODE,
  integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
  tracePropagationTargets: [import.meta.env.VITE_BACKEND_URL],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

app.mount('#app');
