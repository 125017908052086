import { type NavigationGuard } from 'vue-router';

import { SessionModel } from '@/entities/session';
import { useNotificationStore } from '@shared/ui/singletones/ZNotifications';

import { pages } from '../routes';

export const authGuard: NavigationGuard = async (to, from, next) => {
  console.log('authg');
  const sessionStore = SessionModel.useSessionStore();
  const mustBeAuthed = to.meta.authed ?? null;

  const notificationStore = useNotificationStore();

  if (mustBeAuthed === null || sessionStore.signedIn === mustBeAuthed) {
    next();
  } else if (sessionStore.signedIn === true && !to.meta.authed) {
    next({ name: pages.root._name });
    notificationStore.showSnackbar.message({
      text: 'Будучи авторизованным вы не можете посещать неавторизованные разделы',
    });
  } else {
    next({ name: pages.auth._name });
  }
};
